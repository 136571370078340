(<template>

  <!----------  Danger Level Hint ----------->
  <danger-level-icon v-if="applicationHint"
                     :danger-level="dangerLevel"
                     :panic-percentage="panicPercentage"
                     @mouseleave="closeTooltip"
                     @mouseover="openTooltip($event)"
                     @click="emitClick" />
  <!----------  Invitation Delay Icon Hint -------------->
  <span v-else-if="isInvitationDelay"
        class="invitation-delay__icon"
        @click="emitClick"
        @mouseleave="closeTooltip"
        @mouseover="openTooltip($event)">{{ invitationDelay }}</span>

  <!----------  Normal Icon Hint -------------->
  <span v-else-if="!isAvatar"
        class="hint__icon"
        :class="[classes]"
        @click="emitClick"
        @mouseleave="closeTooltip"
        @mouseover="openTooltip($event)"></span>
  <!---------  Avatar Hint ---------------------->
  <tt-avatar
    v-else-if="isAvatar"
    class="hint_avatar"
    :class="[classes]"
    :avatar="avatarPath"
    @click="emitClick"
    @mouseleave="closeTooltip"
    @mouseover="openTooltip($event)" />
</template>)

<script>
  import helpers from '@/helpers';
  import DangerLevelIcon from '@/components/grid/shared/DangerLevelIcon';

  export default {
    components: {DangerLevelIcon},
    props: {
      classes: {
        type: Array,
        default: () => []
      },
      toolTipText: {
        type: String,
        default: ''
      },
      avatarPath: {
        type: String,
        default: ''
      },
      dangerLevel: {
        type: String,
        default: ''
      },
      panicPercentage: {
        type: [Number, String],
        default: 0
      },
      invitationDelay: {
        type: [Number, String],
        default: 0
      }
    },
    computed: {
      isAvatar() { return this.classes.includes('is_avatar'); },
      applicationHint() { return this.classes.includes('application_hint'); },
      isInvitationDelay() { return this.classes.includes('auto_invite_on_infinite_off_ongoing--delay'); }
    },
    methods: {
      itemPosition(event) {
        const {top, left} = helpers.getElementPosition(event.target);
        return {top: (top) + 'px', left: (left + 20) + 'px'};
      },
      openTooltip(event) {
        if (this.toolTipText) {
          this.$store.commit('TooltipRootStore/setTooltip', {
            data: {
              itemText: this.toolTipText,
              relativePosition: 'top-center'
            },
            absolutePosition: this.itemPosition(event)
          });
        }
      },
      closeTooltip() { this.$store.dispatch('TooltipRootStore/closeTooltip'); },
      emitClick(event) { this.$emit('hintclicked', event); }
    }
  };
</script>
<style src="@assets/css/job_hint_icons.css" scoped></style>
<style src="@assets/css/project_hint_icons.css" scoped></style>
