<template>
  <div class="avatar-select">
    <div :class="{'sk-select--with-icon': icon,
                  'is-with-error': (errors && errors.length)}"
         class="sk-select__wrapper">
      <tt-avatar v-if="preselectedValue"
                 class="sk-select__avatar selected-avatar"
                 :avatar="currentValue"
                 @avatarclick="toggleSelectState"
                 @touchstart="touchStartHandler"
                 @touchend="touchHandler" />
      <tt-avatar v-else
                 class="add-manager__icon"
                 @avatarclick="toggleSelectState"
                 @touchstart="touchStartHandler"
                 @touchend="touchHandler" />
      <div ref="customSelect"
           :class="[typeClass,
                    {'is-opened': isSelectOpened},
                    {'is-disabled': disabled}]"
           class="sk-select">
        <input ref="typingInput"
               v-model="typingInputValue"
               :autofocus="autofocus"
               :class="{'is-not-focusable': disabled}"
               :maxlength="typeMaxlength"
               type="text"
               class="sk-select__typing-input"
               @keyup.40="downArrowHandler"
               @keyup.38="upArrowHandler"
               @keydown.13.stop.prevent="pressEnterLogic"
               @keydown.9="pressTabLogic"
               @focus="focusLogic" />
        <ul v-if="isSelectOpened"
            ref="csList"
            class="sk-select__list">
          <div v-for="item in renderedList"
               :key="makeUniqueKey(item.id)"
               class="sk-select__list-item-wrapper"
               :class="[itemsClass(item.id), {'is-highlighted': item.id === currentHighlightedItemObj.id,
                                              'is-marked': isMarked(item.id),
                                              'is-chosen': preselectedValue == item.id,
                                              'is-category': item.items,
                                              'is-unselect': item.id == 0,
                                              'is-ignored': ignoreCategories && item.items,
                                              'is-sub-category': item.itemType == 'sub-item'}]"
               @click="itemClickLogic(item.id, item.items, $event)">
            <tt-avatar v-if="item.id != 0"
                       class="sk-select__item-avatar"
                       :avatar="item.avatarPath" />
            <li :data-desktop-month-name="item.desktop"
                :data-mobile-month-name="item.mobile"
                class="sk-select__item-text js-list-item"
                v-html="setIdLikeName ? item.id : item.name"></li>
          </div>
        </ul>
      </div>
      <div v-if="previewValue"
           class="sk-select__selected-preview"
           v-html="previewValue(selectedItem)"></div>
    </div>
  </div>
</template>

<script>
  import {skSelect} from '@skiwo/sk-basic-components';

  export default {
    extends: skSelect,
    props: {
      avatarPath: {
        type: String,
        default: ''
      },
    },
    computed: {
      currentValue() {
        if ((this.preselectedValue || this.preselectedValue === 0) && this.preselectedValue !== 'undefined') {
          if (this.constList) {
            for (const obj of this.constList) {
              if (String(obj.id) == this.preselectedValue) {
                return this.type == 'countryCodes' ? obj.id : (obj.avatarPath || obj.desktop);
              }
              if (obj.items && obj.items.length) {
                for (const item of obj.items) {
                  if (String(item.id) == this.preselectedValue) {
                    return `${obj.avatarPath} - ${item.avatarPath}`;
                  }
                }
              }
            }
          }
        }
        return this.defaultValue;
      },
    },
    methods: {
      closeSelect(eve) {
        if (eve.target.className.indexOf('tt-avatar') === -1) {
          this.isSelectOpened = false;
        } else if (eve.target.className.indexOf('sk-select__item-avatar') !== -1 && this.isSelectOpened) {
          this.isSelectOpened = false;
        }
      },
    }
  };
</script>
<style>
.avatar-select .sk-select,
.avatar-select .sk-select.is-opened {
  height: 0;
  border: none;
}

.avatar-select .sk-select.is-opened .sk-select__list {
  width: fit-content;
  min-width: 100px;
}
</style>
<style scoped>
.sk-select__avatar,
.sk-select__item-avatar {
  width: 30px;
  min-width: 25px;
  height: 30px;
}

.selected-avatar {
  border: 2px solid #ff5b24;
}

.sk-select__list-item-wrapper {
  display: flex;
  align-items: center;
  width: auto;
  padding: 7px 8px;
}

.sk-select__item-text {
  padding-left: 8px;
}

.add-manager__icon {
  flex-shrink: 0;
  width: 30px;
  height: 30px;
  border: 2px solid #ff5b24;
  border: 2px solid var(--color-primary);
  background-image: url(~@assets/imgs/salita/icons/add_user_icon.svg);
  background-image: var(--image-add-manager);
  background-size: 15px auto;
}
</style>
