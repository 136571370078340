(<template>
  <div class="notes">
    <div class="notes__wrapper"
         @click="onNoteClick">
      <template v-if="firstRow">
        <p class="last-note"
           :style="noteStyle">{{ firstRow }}</p>
        <p class="notes__date-author">{{ secondRow }}</p>
      </template>
      <div v-else
           class="notes__no-notes">
        <span>{{ $gettext('No notes') }}</span>
      </div>
    </div>
  </div>
</template>)

<script>
  export default {
    computed: {
      lastAdminDiscussionAuthorName() { return this.params.data?.lastAdminDiscussionAuthorName; },
      lastAdminDiscussionCreatedAt() { return this.$moment(this.params.data?.lastAdminDiscussionCreatedAt).format('YYYY.MM.DD HH:mm'); },
      lastAdminDiscussionText() { return this.params.data?.lastAdminDiscussionText; },
      dateAndAuthor() { return this.lastAdminDiscussionCreatedAt + ' ' + this.$gettext('by') + ' ' + this.lastAdminDiscussionAuthorName; },
      firstRow() { return this.lastAdminDiscussionText; },
      secondRow() { return this.dateAndAuthor; },
      noteStyle() { return {}; }
    },
    methods: {
      onNoteClick() {}
    }
  };
</script>
<style scoped>
.notes {
  width: 100%;
  height: 100%;
}

.last-note,
.notes__date-author {
  overflow: hidden;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.notes__wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  max-height: 50px;
  margin: 4px 4px 4px 0;
  padding: 6px 8px;
  border-radius: 8px;
  background-color: #f4f5f7;
}

.notes__date-author {
  color: #555;
  font-size: 12px;
}

.notes__no-notes {
  height: 35px;
  margin: auto;
  padding-top: 5px;
  color: #808080;
}
</style>
