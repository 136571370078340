<template>
  <section class="assignees-filter">
    <sk-multiselect :items-list="managers"
                    :preselected-value="filterAssignees"
                    :default-value="defaultValue"
                    @checkboxeschanged="onInputBoxChanged" />
  </section>
</template>

<script>
  export default {
    data() {
      return {
        filterAssignees: []
      };
    },
    computed: {
      managers() { return this.$store.getters.managersForSelect || []; },
      defaultValue() { return this.$gettext('Select assignee'); },

    },
    methods: {
      onInputBoxChanged(value) {
        this.filterAssignees = [...value];
        if (this.filterAssignees === '') {
          return;
        }
        this.params.parentFilterInstance((instance) => {
          instance.onFloatingFilterChanged('inList', value);
        });
      },

      onParentModelChanged(parentModel) {
        // When the filter is empty we will receive a null value here
        if (!parentModel) {
          this.filterAssignees = '';
        }
      }
    }
  };
</script>

<style>
.assignees-filter .sk-select {
  background-color: #fff;
}

.assignees-filter .sk-select__list {
  width: fit-content;
}

.assignees-filter .sk-select__value {
  color: #333;
  font-weight: normal;
  font-size: 13px;
}

.assignees-filter {
  width: 100%;
}
</style>
