// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@assets/imgs/salita/job_grid/eye_icon_black.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "\n.viewers[data-v-d75d7be0] {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  -webkit-box-pack: start;\n      -ms-flex-pack: start;\n          justify-content: flex-start;\n  height: 100%;\n  border-radius: 8px;\n}\n.viewers__avatar-cont[data-v-d75d7be0],\n.viewers__no-viewers-cont[data-v-d75d7be0] {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  line-height: 15px;\n}\n.viewers__no-viewers-cont[data-v-d75d7be0] {\n  overflow: hidden;\n}\n.eye-icon[data-v-d75d7be0] {\n  width: 17px;\n  height: 15px;\n  margin-left: 10px;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-repeat: no-repeat;\n}\n.viewers__avatar-list[data-v-d75d7be0] {\n  margin-top: 5px;\n  margin-left: 5px;\n}\n.viewers__no-viewers-text[data-v-d75d7be0] {\n  overflow: hidden;\n  margin-left: 5px;\n  text-overflow: ellipsis;\n}\n.viewers__manager-select[data-v-d75d7be0] {\n  max-width: 30px;\n}\n", ""]);
// Exports
module.exports = exports;
