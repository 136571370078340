<script>
  import TextFilter from '@/components/grid/filters/shared/TextFilter';

  export default {
    extends: TextFilter,
    computed: {
      placeholder() { return this.$gettext('Name, phone, email, enterprise...'); },
    }
  };
</script>
