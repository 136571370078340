// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("@assets/imgs/salita/icons/add_user_icon.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "\n.sk-select__avatar[data-v-ac1d43c8],\n.sk-select__item-avatar[data-v-ac1d43c8] {\n  width: 30px;\n  min-width: 25px;\n  height: 30px;\n}\n.selected-avatar[data-v-ac1d43c8] {\n  border: 2px solid #ff5b24;\n}\n.sk-select__list-item-wrapper[data-v-ac1d43c8] {\n  display: -webkit-box;\n  display: -ms-flexbox;\n  display: flex;\n  -webkit-box-align: center;\n      -ms-flex-align: center;\n          align-items: center;\n  width: auto;\n  padding: 7px 8px;\n}\n.sk-select__item-text[data-v-ac1d43c8] {\n  padding-left: 8px;\n}\n.add-manager__icon[data-v-ac1d43c8] {\n  -ms-flex-negative: 0;\n      flex-shrink: 0;\n  width: 30px;\n  height: 30px;\n  border: 2px solid #ff5b24;\n  border: 2px solid var(--color-primary);\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-image: var(--image-add-manager);\n  background-size: 15px auto;\n}\n", ""]);
// Exports
module.exports = exports;
