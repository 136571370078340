<template>
  <div class="hint-select">
    <div :class="{'sk-select--with-icon': icon,
                  'is-with-error': (errors && errors.length)}"
         class="sk-select__wrapper">
      <danger-level-icon v-if="isDangerLevelHint && preselectedValue && selectedItem.dangerLevel"
                         :danger-level="selectedItem.dangerLevel"
                         class="danger-level--selected-icon"
                         :panic-percentage="selectedItem.panicPercentage"
                         @touchstart="touchStartHandler"
                         @touchend="touchHandler"
                         @click="toggleSelectState" />
      <tt-avatar v-else-if="preselectedValue"
                 :class="[currentValue]"
                 class="hint__icon hint__icon--selected"
                 @avatarclick="toggleSelectState"
                 @touchstart="touchStartHandler"
                 @touchend="touchHandler" />
      <tt-avatar v-else
                 :class="[hintIcon]"
                 class="hint__icon"
                 @avatarclick="toggleSelectState"
                 @touchstart="touchStartHandler"
                 @touchend="touchHandler" />
      <div ref="customSelect"
           :class="[typeClass,
                    {'is-opened': isSelectOpened},
                    {'is-disabled': disabled}]"
           class="sk-select">
        <input ref="typingInput"
               v-model="typingInputValue"
               :autofocus="autofocus"
               :class="{'is-not-focusable': disabled}"
               :maxlength="typeMaxlength"
               type="text"
               class="sk-select__typing-input"
               @keyup.40="downArrowHandler"
               @keyup.38="upArrowHandler"
               @keydown.13.stop.prevent="pressEnterLogic"
               @keydown.9="pressTabLogic"
               @focus="focusLogic" />
        <ul v-if="isSelectOpened"
            ref="csList"
            class="sk-select__list">
          <div v-for="item in renderedList"
               :key="makeUniqueKey(item.id)"
               class="sk-select__list-item-wrapper"
               :class="[itemsClass(item.id), {'is-highlighted': item.id === currentHighlightedItemObj.id,
                                              'is-marked': isMarked(item.id),
                                              'is-chosen': preselectedValue == item.id,
                                              'is-category': item.items,
                                              'is-unselect': item.id == 0,
                                              'is-ignored': ignoreCategories && item.items,
                                              'is-sub-category': item.itemType == 'sub-item'}]"
               @click="itemClickLogic(item.id, item.items, $event)">
            <template v-if="item.id != -1">
              <danger-level-icon v-if="isDangerLevelHint && item.dangerLevel"
                                 :danger-level="item.dangerLevel"
                                 :panic-percentage="item.panicPercentage" />
              <tt-avatar v-else
                         :class="[item.iconClass]"
                         class="hint__icon sk-select__item-avatar"
                         :avatar="item.avatarPath" />
            </template>
            <li :data-desktop-month-name="item.desktop"
                :data-mobile-month-name="item.mobile"
                class="sk-select__item-text js-list-item"
                v-html="setIdLikeName ? item.id : item.name"></li>
          </div>
        </ul>
      </div>
      <div v-if="previewValue"
           class="sk-select__selected-preview"
           v-html="previewValue(selectedItem)"></div>
    </div>
  </div>
</template>

<script>
  import {skSelect} from '@skiwo/sk-basic-components';
  import DangerLevelIcon from '@/components/grid/shared/DangerLevelIcon';

  export default {
    components: {
      'danger-level-icon': DangerLevelIcon
    },
    extends: skSelect,
    props: {
      avatarPath: {
        type: String,
        default: ''
      },
      hintIcon: {
        type: String,
        default: ''
      },
      type: {
        type: String,
        default: ''
      }
    },
    computed: {
      currentValue() {
        if ((this.preselectedValue || this.preselectedValue === 0) && this.preselectedValue !== 'undefined') {
          if (this.constList) {
            for (const obj of this.constList) {
              if (String(obj.id) == this.preselectedValue) {
                return this.type == 'countryCodes' ? obj.id : (obj.iconClass || obj.desktop);
              }
              if (obj.items && obj.items.length) {
                for (const item of obj.items) {
                  if (String(item.id) == this.preselectedValue) {
                    return `${obj.iconClass} - ${item.iconClass}`;
                  }
                }
              }
            }
          }
        }
        return this.defaultValue;
      },
      isDangerLevelHint() { return this.type === 'danger-level'; }
    },
    methods: {
      closeSelect(eve) {
        if (eve && eve?.target && eve?.target?.className) {
          if (eve?.target?.className?.indexOf('tt-avatar') === -1 && eve?.target?.className?.indexOf('danger-level-icon__text') === -1) {
            this.isSelectOpened = false;
          } else if (eve?.target?.className?.indexOf('sk-select__item-avatar') !== -1 && this.isSelectOpened) {
            this.isSelectOpened = false;
          }
        }
      },
    }
  };
</script>
<style>
.hint-select .sk-select,
.hint-select .sk-select.is-opened {
  height: 0;
  border: none;
}

.hint-select .sk-select.is-opened .sk-select__list {
  width: fit-content;
  min-width: 100px;
}

.hint__icon.tt-avatar {
  box-shadow: none;
}

.hint__icon.tt-avatar:hover {
  box-shadow: 0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 20%);
}
</style>
<style scoped>
.sk-select__avatar {
  width: 30px;
  min-width: 25px;
  height: 30px;
}

.sk-select__list-item-wrapper {
  display: flex;
  align-items: center;
  width: auto;
  padding: 7px 8px;
}

.sk-select__item-text {
  padding-left: 8px;
}
</style>
<style src="@assets/css/job_hint_icons.css" scoped></style>
<style src="@assets/css/project_hint_icons.css" scoped></style>
