var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"avatar-select"},[_c('div',{staticClass:"sk-select__wrapper",class:{'sk-select--with-icon': _vm.icon,
                'is-with-error': (_vm.errors && _vm.errors.length)}},[(_vm.preselectedValue)?_c('tt-avatar',{staticClass:"sk-select__avatar selected-avatar",attrs:{"avatar":_vm.currentValue},on:{"avatarclick":_vm.toggleSelectState,"touchstart":_vm.touchStartHandler,"touchend":_vm.touchHandler}}):_c('tt-avatar',{staticClass:"add-manager__icon",on:{"avatarclick":_vm.toggleSelectState,"touchstart":_vm.touchStartHandler,"touchend":_vm.touchHandler}}),_vm._v(" "),_c('div',{ref:"customSelect",staticClass:"sk-select",class:[_vm.typeClass,
                  {'is-opened': _vm.isSelectOpened},
                  {'is-disabled': _vm.disabled}]},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.typingInputValue),expression:"typingInputValue"}],ref:"typingInput",staticClass:"sk-select__typing-input",class:{'is-not-focusable': _vm.disabled},attrs:{"autofocus":_vm.autofocus,"maxlength":_vm.typeMaxlength,"type":"text"},domProps:{"value":(_vm.typingInputValue)},on:{"keyup":[function($event){if(!$event.type.indexOf('key')&&$event.keyCode!==40){ return null; }return _vm.downArrowHandler.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&$event.keyCode!==38){ return null; }return _vm.upArrowHandler.apply(null, arguments)}],"keydown":[function($event){if(!$event.type.indexOf('key')&&$event.keyCode!==13){ return null; }$event.stopPropagation();$event.preventDefault();return _vm.pressEnterLogic.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&$event.keyCode!==9){ return null; }return _vm.pressTabLogic.apply(null, arguments)}],"focus":_vm.focusLogic,"input":function($event){if($event.target.composing){ return; }_vm.typingInputValue=$event.target.value}}}),_vm._v(" "),(_vm.isSelectOpened)?_c('ul',{ref:"csList",staticClass:"sk-select__list"},_vm._l((_vm.renderedList),function(item){return _c('div',{key:_vm.makeUniqueKey(item.id),staticClass:"sk-select__list-item-wrapper",class:[_vm.itemsClass(item.id), {'is-highlighted': item.id === _vm.currentHighlightedItemObj.id,
                                            'is-marked': _vm.isMarked(item.id),
                                            'is-chosen': _vm.preselectedValue == item.id,
                                            'is-category': item.items,
                                            'is-unselect': item.id == 0,
                                            'is-ignored': _vm.ignoreCategories && item.items,
                                            'is-sub-category': item.itemType == 'sub-item'}],on:{"click":function($event){return _vm.itemClickLogic(item.id, item.items, $event)}}},[(item.id != 0)?_c('tt-avatar',{staticClass:"sk-select__item-avatar",attrs:{"avatar":item.avatarPath}}):_vm._e(),_vm._v(" "),_c('li',{staticClass:"sk-select__item-text js-list-item",attrs:{"data-desktop-month-name":item.desktop,"data-mobile-month-name":item.mobile},domProps:{"innerHTML":_vm._s(_vm.setIdLikeName ? item.id : item.name)}})],1)}),0):_vm._e()]),_vm._v(" "),(_vm.previewValue)?_c('div',{staticClass:"sk-select__selected-preview",domProps:{"innerHTML":_vm._s(_vm.previewValue(_vm.selectedItem))}}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }