<template>
  <div class="danger-level-icon__wrapper"
       @click="emitClick"
       @touchstart="emitTouchStart"
       @touchend="emitTouchEnd"
       @mouseleave="emitMouseLeave"
       @mouseover="emitMouseOver($event)">
    <svg class="danger-level-icon"
         @click.prevent.stop="">
      <circle class="progress-bg"
              cx="13"
              cy="18"
              :style="'stroke:' + progressColor.bg +';stroke-width: 0.5px;'"
              r="12" />
      <circle class="progress-bg"
              cx="13"
              cy="18"
              :style="compProgressBgStyle"
              r="11" />
      <circle class="progress"
              :style="compProgressStyle"
              cx="13"
              cy="18"
              r="11" />
    </svg>
    <h2 class="danger-level-icon__text">{{ dangerLevel }}</h2>
  </div>
</template>

<script>
  export default {
    props: {
      dangerLevel: {
        type: String,
        default: ''
      },
      panicPercentage: {
        type: [Number, String],
        default: 0
      },
    },
    computed: {
      progressColor() {
        switch (this.dangerLevel) {
          case 'A':
            return {fg: '#54BF54', bg: 'rgb(84, 191, 84, 0.2)'};
          case 'B':
            return {fg: '#246DFF', bg: 'rgb(36, 109, 255, 0.2)'};
          case 'C':
            return {fg: '#FAD623', bg: 'rgb(250, 214, 35, 0.2)'};
          case 'D':
            return {fg: '#FF7F24', bg: 'rgb(255, 127, 36, 0.2)'};
          case 'E':
          case 'F':
          case '0':
            return {fg: '#FF3624', bg: 'rgb(255, 54, 36, 0.2)'};
          default:
            return {fg: '#54BF54', bg: 'fff'};
        }
      },
      compProgressStyle() {
        return {
          stroke: this.progressColor.fg,
          'stroke-dashoffset': `calc(100 - (100 * ${Number(this.panicPercentage)}) / 145)`
        };
      },
      compProgressBgStyle() {
        return {
          stroke: this.progressColor.bg
        };
      },
    },
    methods: {
      emitClick(event) { this.$emit('click', event); },
      emitMouseLeave(event) { this.$emit('mouseleave', event); },
      emitMouseOver(event) { this.$emit('mouseover', event); },
      emitTouchStart(event) { this.$emit('touchstart', event); },
      emitTouchEnd(event) { this.$emit('touchend', event); }
    }
  };
</script>
<style scoped>
/* -----Danger Level Percentage----- */
.progress-bg {
  fill: none;
  stroke: #1a2c34;
  stroke-width: 2px;
}

.progress {
  fill: none;
  stroke: aqua;
  transform: rotate(-90deg);
  transform-origin: 50% 60%;
  animation: progress-1 0.3ms ease-out;
  stroke-dasharray: 100;
  stroke-dashoffset: calc(100 - (100 * 90) / 230);
  stroke-width: 2px;
  stroke-linecap: round;
}

.danger-level-icon__text {
  position: absolute;
  top: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 50%;
  font-size: 14px;
  transition: 0.5s;
}

.danger-level-icon {
  width: 30px;
  height: 30px;
  padding: 0 2px;
}

.danger-level-icon__wrapper {
  position: relative;
  cursor: pointer;
}

</style>
