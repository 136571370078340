(<template>
  <div v-if="showBlock"
       class="viewers">
    <div>
      <avatar-select :items-list="computedManagersList"
                     :preselected-value="assignedManagerUid"
                     class="viewers__manager-select"
                     @csvaluechanged="changeSelectedManager" />
    </div>
    <template v-if="showViewers">
      <div v-if="viewingManagersObj && viewingManagersObj.length"
           class="viewers__avatar-cont">
        <span class="eye-icon"></span>
        <avatars-list
          class="viewers__avatar-list"
          :max-avatars="4"
          :avatars="viewingManagersObj" />
      </div>
      <div v-else
           class="viewers__no-viewers-cont">
        <span class="eye-icon"></span>
        <p class="viewers__no-viewers-text">{{ $gettext('No viewers') }}</p>
      </div>
    </template>
  </div>
</template>)

<script>
  import AvatarsList from '@/components/assignment_components/dashboard/manager/AvatarsList';
  import AvatarSelect from '@/components/shared_components/AvatarSelect';
  import {mapState} from 'vuex';

  export default {
    components: {
      'avatars-list': AvatarsList,
      'avatar-select': AvatarSelect
    },
    props: {
      data: {
        type: Object,
        default: () => {}
      }
    },
    data() {
      return {
        assignedManagerUid: '',
        unassignManagerItem: [{
          uid: 0,
          name: this.$gettext('Un-assign'),
          avatarPath: '',
        }]
      };
    },
    computed: {
      propData() { return this.params?.data ? this.params?.data : this.data; },
      ...mapState('ManagerSocketDataStore', {
        allViewingManagers: (state) => state.viewingManagers || {},
      }),
      showBlock() { return true; },
      foregroundViewingManagerIds() { return this.allViewingManagers?.foreground ? this.allViewingManagers?.foreground[this.jobId] || [] : []; },
      foregroundViewingManager() {
        const foregroundManagers = [];
        this.allViewingManagers?.foreground ? this.allViewingManagers?.foreground[this.jobId]?.forEach((uid) => {
          foregroundManagers.push({uid, status: 'active'});
        }) : null;

        return foregroundManagers;
      },
      backgroundViewingManager() {
        const backgroundManagers = [];
        this.allViewingManagers[this.jobId]?.forEach((uid) => {
          if (!this.foregroundViewingManagerIds.includes(uid)) backgroundManagers.push({uid, status: 'inactive'});
        });

        return backgroundManagers;
      },
      filteredViewingManagers() {
        return this.backgroundViewingManager.concat(this.foregroundViewingManager);
      },
      jobId() { return this.propData?.id; },
      managersList() { return this.$store.state.selectsData.managers; },
      computedManagersList() {
        const allManagers = [...this.unassignManagerItem, ...this.managersList];
        if (allManagers && allManagers.length) {
          return allManagers.map((manager) => {
            return {
              id: manager.uid,
              name: manager.name ? manager.name : manager.email,
              avatarPath: manager.avatarPath,
            };
          });
        }
        return [];
      },
      viewingManagersObj() {
        return this.filteredViewingManagers?.map((viewingUser) => {
          const idx = this.managersList?.findIndex((manager) => manager.uid === viewingUser.uid);
          if (idx !== null && idx !== undefined) {
            return {
              ...this.managersList[idx],
              id: this.managersList[idx].uid,
              status: viewingUser.status
            };
          }
        });
      },
      showViewers() { return true; }
    },
    methods: {
      changeSelectedManager(value) {
        if (value == 0) {
          this.assignedManagerUid = '';
        } else {
          this.assignedManagerUid = value;
        }
        const data = {
          payload: {
            manager_uid: this.assignedManagerUid === '' ? null : this.assignedManagerUid,
          },
          id: this.jobId
        };
        this.$store.dispatch('ManagerSocketDataStore/assignManagerToJobAssignment', data);
      }
    },
    beforeMount() {
      this.assignedManagerUid = this.params.data?.assignedManagerUid || '';
    },
  };
</script>
<style>
.viewers .avatars-list {
  margin-left: 15px;
}
</style>
<style scoped>
.viewers {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  border-radius: 8px;
}

.viewers__avatar-cont,
.viewers__no-viewers-cont {
  display: flex;
  align-items: center;
  line-height: 15px;
}

.viewers__no-viewers-cont {
  overflow: hidden;
}

.eye-icon {
  width: 17px;
  height: 15px;
  margin-left: 10px;
  background-image: url(~@assets/imgs/salita/job_grid/eye_icon_black.svg);
  background-repeat: no-repeat;
}

.viewers__avatar-list {
  margin-top: 5px;
  margin-left: 5px;
}

.viewers__no-viewers-text {
  overflow: hidden;
  margin-left: 5px;
  text-overflow: ellipsis;
}

.viewers__manager-select {
  max-width: 30px;
}
</style>
