(<template>
  <div v-if="showBlock"
       class="hints is-progress-bar"
       :class="{'in-progress': progressActive}">
    <div v-if="hasAwardedInterpreter">
      <avatar-select :items-list="computedManagersList"
                     :preselected-value="assignedManagerUid"
                     class="hints__manager-select"
                     @csvaluechanged="changeSelectedManager" />
    </div>
    <div class="hints__wrapper">
      <hint-icon v-for="hint in hintIcons"
                 :key="hint.id"
                 :classes="hint.classes"
                 :tool-tip-text="hint.toolTipText"
                 :panic-percentage="panicPercentage"
                 :invitation-delay="invitationDelayFormatted"
                 :danger-level="dangerLevelStatus.level"
                 :avatar-path="directlyInvitedInterpreterAvatarUrl"
                 @hintclicked="hint.onClick" />
    </div>
  </div>
</template>)

<script>
  import HintIcon from '@/components/grid/cells/hints/HintIcon';
  import AvatarSelect from '@/components/shared_components/AvatarSelect';
  import ManagerJobEditInfoMixin from '@/mixins/ManagerJobEditInfoMixin';
  import helpers from '@/helpers';

  export default {
    components: {
      'hint-icon': HintIcon,
      'avatar-select': AvatarSelect
    },
    mixins: [ManagerJobEditInfoMixin],
    data() {
      return {
        autoInviteToolTipText: '',
        applicationToolTipText: '',
        autoAwardToolTipText: '',
        sessionTypeToolTipText: '',
        confirmedByToolTipText: '',
        standByToolTipText: '',
        alternativeToolTipText: '',
        qualificationToolTipText: '',
        genderToolTipText: '',
        directJobToolTipText: '',
        assignedManagerUid: '',
        unassignManagerItem: [{
          uid: 0,
          name: this.$gettext('Un-assign'),
          avatarPath: '',
        }]
      };
    },
    computed: {
      propData() { return this.params?.data ? this.params?.data : this.data; },
      showBlock() { return true; },
      hasAwardedInterpreter() { return this.params.hasAwardedInterpreter; },
      minutesText() { return this.$gettext('minutes'); },
      magicText() { return this.$gettext('magic'); },
      // -- Assigned Manager --//
      managersList() { return this.$store.state.selectsData.managers; },
      computedManagersList() {
        const allManagers = [...this.unassignManagerItem, ...this.managersList];
        if (allManagers && allManagers.length) {
          return allManagers.map((manager) => {
            return {
              id: manager.uid,
              name: manager.name ? manager.name : manager.email,
              avatarPath: manager.avatarPath,
            };
          });
        }
        return [];
      },
      // -- Auto Invite --//
      autoInvite() { return this.params.data?.autoInvite; },
      infiniteAutoInvite() { return this.params.data?.infiniteAutoInvite; },
      inviteStatus() { return this.params.data?.inviteStatus; },
      invitationDelay() { return this.params.data?.invitationDelay; },
      nextInvitationTime() { return this.params.data?.nextInvitationTime; },
      invitationDelayFormatted() {
        if (this.invitationDelay >= 60) return Math.round(this.invitationDelay / 60) + 'h';
        else return this.invitationDelay;
      },
      invitePaused() { return this.inviteStatus === 'paused'; },
      inviteDisabled() { return this.inviteStatus === 'disabled'; },
      inviteFinished() { return this.inviteStatus === 'finished'; },
      inviteOngoing() { return this.inviteStatus === 'ongoing'; },
      notSuitableInterpreters() { return this.inviteStatus === 'no_suitable_interpreters'; },
      // -- Application --//
      applicationsCount() { return this.params.data?.applicationsCount; },
      invitationsCount() { return this.params.data?.invitationsCount; },
      lastWithdrawalAt() { return this.params.data?.lastWithdrawalAt; },
      panicPercentage() { return this.params.data?.panicPercentage; },
      dangerLevel() { return this.params.data?.dangerLevel; },
      // -- Auto-award --//
      autoAward() { return this.params.data?.autoAward; },
      // -- Session Type --//
      sessionType() { return this.params.data?.sessionType; },
      sessionTypeProcessing() { return this.params.data?.sessionTypeProcessing; },
      autoUpdateToSessionType() { return this.params.data?.autoUpdateToSessionType; },
      autoUpdateTime() { return this.params.data?.autoUpdateTime; },
      autoUpdateTimeHasPast() { return this.$moment().isAfter(this.$moment(this.autoUpdateTime)); },
      autoUpdateTimeF() { return this.$moment(this.params.data?.autoUpdateTime).format('YYYY-MM-DD HH:mm:ss'); },
      videoPlatformName() { return this.params.data?.videoPlatformName || ''; },
      isSalitaVideoPlatform() { return this.videoPlatformName == 'Salita'; },
      standbyProcessing() { return this.params.data?.standbyProcessing; },
      // -- Confirmed By --//
      confirmationDeadline() { return this.params.data?.confirmationDeadline; },
      confirmationBy() { return this.params.data?.confirmationBy; },
      // -- Alternative Lang --//
      alternativeLangToId() { return this.params.data?.alternativeLangToId; },
      alternativeLanguageActivated() { return this.params.data?.alternativeLanguageActivated; },
      // -- Qualification --//
      qualificationProcessing() { return this.params.data?.qualificationProcessing; },
      qualificationName() { return this.params.data?.qualificationName; },
      specificQualificationRequested() { return this.params.data?.specificQualificationRequested; },
      // -- Gender --//
      genderProcessing() { return this.params.data?.genderProcessing; },
      gender() { return this.params.data?.gender; },
      // -- Direct Job --//
      directProcessing() { return this.params.data?.directProcessing; },
      directlyInvitedInterpreterName() { return this.params.data?.directlyInvitedInterpreterName; },
      directlyInvitedInterpreterAbandonedAt() { return this.params.data?.directlyInvitedInterpreterAbandonedAt; },
      directlyInvitedInterpreterAvatarUrl() { return this.params.data?.directlyInvitedInterpreterAvatarUrl; },
      // -- Last Activity --//
      lastActivityType() { return this.params.data?.lastActivityType; },
      lastActivityAt() { return this.$moment(this.params.data?.lastActivityCreatedAt).format('DD.MM.YYYY, HH:mm'); },

      autoInviteStatus() {
        if (this.infiniteAutoInvite) {
          if (this.inviteOngoing) {
            this.setAutoInviteToolTipText(this.$gettext('Auto-invite is ON. Infinite is ON. Invitations are scheduled.'));
            return 'auto_invite_on_infinite_on_ongoing';
          } else if (this.inviteFinished) {
            this.setAutoInviteToolTipText(this.$gettext('Auto-invite is finished. Infinite is ON. Everyone suitable has been invited.'));
            return 'auto_invite_on_infinite_on_finished';
          } else if ((this.inviteDisabled) || (this.notSuitableInterpreters)) {
            this.setAutoInviteToolTipText(this.$gettext('The admin has turned off the invitation process manually or the job <br> is not suitable for auto-invite. Infinite is ON.'));
            return 'auto_invite_off_infinite_on_disabled';
          } else if (this.invitePaused) {
            this.setAutoInviteToolTipText(this.$gettext('Auto-invite is OFF. Infinite is OFF. There are more suitable interpreters.'));
            return 'auto_invite_off_infinite_on_paused';
          }
        } else if (!this.infiniteAutoInvite) {
          const temp = this.$gettext('Auto-invite is ON. Infinite is OFF. Invitations are scheduled (%{time}).');
          if (this.inviteOngoing && !this.invitationDelay) {
            this.setAutoInviteToolTipText(this.$gettextInterpolate(temp, {time: this.magicText}));
            return 'auto_invite_on_infinite_off_ongoing';
          } else if (this.inviteOngoing && this.invitationDelay) {
            this.setAutoInviteToolTipText(this.$gettextInterpolate(temp, {time: this.invitationDelay + ' ' + this.minutesText}));
            return 'auto_invite_on_infinite_off_ongoing--delay';
          } else if (this.inviteFinished) {
            this.setAutoInviteToolTipText(this.$gettext('Auto-invite is finished. Infinite is OFF. Everyone suitable has <br> been invited.'));
            return 'auto_invite_on_infinite_off_finished';
          } else if ((this.inviteDisabled) || (this.notSuitableInterpreters)) {
            this.setAutoInviteToolTipText(this.$gettext('The admin has turned off the invitation process manually or the job <br> is not suitable for auto-invite. Infinite is OFF.'));
            return 'auto_invite_off_infinite_off_disabled';
          } else if (this.invitePaused) {
            this.setAutoInviteToolTipText(this.$gettext('Auto-invite is OFF. Infinite is OFF. There are more suitable interpreters.'));
            return 'auto_invite_off_infinite_off_paused';
          }
        }
      },
      dangerLevelStatus() {
        let temp = '';
        switch (this.dangerLevel) {
          case 1:
            temp = this.$gettext('%{invitationsCount} invitations has been sent. <br> %{applicationsCount} applications.');
            this.setApplicationToolTipText(this.$gettextInterpolate(temp, {applicationsCount: this.applicationsCount, invitationsCount: this.invitationsCount}));
            return {level: 'A', class: 'application_hint'};
          case 2:
            this.setApplicationToolTipText(this.$gettext('Last invitation was sent within 30 minutes. <br> No applications.'));
            return {level: 'B', class: 'application_hint'};
          case 3:
            this.setApplicationToolTipText(this.$gettext('Less than 7 invitations has been sent. <br> No applications.'));
            return {level: 'C', class: 'application_hint'};
          case 4:
            this.setApplicationToolTipText(this.$gettext('7, 8 or 9 invitations has been sent. <br> No applications.'));
            return {level: 'D', class: 'application_hint'};
          case 5:
            this.setApplicationToolTipText(this.$gettext('10 or more invitations has been sent. <br> No applications.'));
            return {level: 'E', class: 'application_hint'};
          case 6:
            this.setApplicationToolTipText(this.$gettext('10 or more invitations has been sent and the interpretation <br> requirements are at the lowest. <br> No applications.'));
            return {level: 'F', class: 'application_hint'};
          case 100:
            temp = this.$gettext('Interpreter withdrew. <br> %{lastWithdrawalAt}');
            this.setApplicationToolTipText(this.$gettextInterpolate(temp, {lastWithdrawalAt: this.lastWithdrawalAt}));
            return {level: '0', class: 'application-warning--orange'};
          default:
            return {level: '0', class: 'application_hint'};
        }
      },
      autoAwardStatus() {
        if (this.autoAward) {
          this.setAutoAwardToolTipText(this.$gettext('Auto-award is ON.'));
          return 'auto_award_on';
        } else if (!this.autoAward) {
          this.setAutoAwardToolTipText(this.$gettext('Auto-award is OFF.'));
          return 'auto_award_off';
        }
      },
      sessionTypeStatus() {
        let temp = '';
        const videoPlatformName = this.videoPlatformName ? this.videoPlatformName + '.' : '';
        switch (this.sessionType) {
          case 'in_person':
            if (this.sessionTypeProcessing === 'session_type_no_action') {
              this.setSessionTypeToolTipText(this.$gettext('In-person assignment'));
              return 'in_person_no_requirement';
            } else if (this.sessionTypeProcessing === 'session_type_cancel_not_found') {
              temp = this.$gettext('Cancel this assignment if in-person can\'t be confirmed.<br> %{autoUpdateTimeF}');
              this.setSessionTypeToolTipText(this.$gettextInterpolate(temp, {autoUpdateTimeF: this.autoUpdateTimeF}));
              return 'in_person_cancel';
            } else if (this.sessionTypeProcessing === 'session_type_auto_update') {
              if (this.autoUpdateToSessionType === 'phone') {
                temp = this.$gettext('Set this assignment to phone if in-person can\'t be confirmed.<br> %{autoUpdateTimeF}');
                this.setSessionTypeToolTipText(this.$gettextInterpolate(temp, {autoUpdateTimeF: this.autoUpdateTimeF}));
                return 'in_person_changeable';
              } else if (this.autoUpdateToSessionType === 'video') {
                temp = this.$gettext('Set this assignment to video if in-person can\'t be confirmed.<br> %{autoUpdateTimeF}');
                this.setSessionTypeToolTipText(this.$gettextInterpolate(temp, {autoUpdateTimeF: this.autoUpdateTimeF}));
                return 'in_person_changeable';
              }
            }
            break;
          case 'video':
            if (this.sessionTypeProcessing === 'session_type_no_action') {
              this.setSessionTypeToolTipText(this.$gettext('Video assignment'));
              return 'video_no_requirement';
            } else if (this.sessionTypeProcessing === 'session_type_cancel_not_found') {
              temp = this.$gettext('%{videoPlatformName} Cancel this assignment if video can\'t be confirmed.<br> %{autoUpdateTimeF}');
              this.setSessionTypeToolTipText(this.$gettextInterpolate(temp, {videoPlatformName, autoUpdateTimeF: this.autoUpdateTimeF}));
              return this.isSalitaVideoPlatform ? 'video_cancel' : 'video_cancel_other';
            } else if (this.sessionTypeProcessing === 'session_type_auto_update') {
              if (this.autoUpdateToSessionType === 'phone') {
                temp = this.$gettext('%{videoPlatformName} Set this assignment to phone if video can\'t be confirmed.<br> %{autoUpdateTimeF}');
                this.setSessionTypeToolTipText(this.$gettextInterpolate(temp, {videoPlatformName, autoUpdateTimeF: this.autoUpdateTimeF}));
                return this.isSalitaVideoPlatform ? 'video_changeable' : 'video_changeable_other';
              } else if (this.autoUpdateToSessionType === 'video') {
                temp = this.$gettext('%{videoPlatformName} This assignment was set to video because in-person couldn\'t be confirmed.<br> %{autoUpdateTimeF}');
                this.setSessionTypeToolTipText(this.$gettextInterpolate(temp, {videoPlatformName, autoUpdateTimeF: this.autoUpdateTimeF}));
                return this.isSalitaVideoPlatform ? 'video_changed' : 'video_changed_other';
              }
            }
            break;
          case 'phone':
            if (this.sessionTypeProcessing === 'session_type_no_action') {
              this.setSessionTypeToolTipText(this.$gettext('Phone assignment.'));
              return 'phone_no_requirement';
            } else if (this.sessionTypeProcessing === 'session_type_cancel_not_found') {
              temp = this.$gettext('Cancel this assignment if video can\'t be confirmed.<br> %{autoUpdateTimeF}');
              this.setSessionTypeToolTipText(this.$gettextInterpolate(temp, {autoUpdateTimeF: this.autoUpdateTimeF}));
              return 'phone_cancel';
            } else if (this.sessionTypeProcessing === 'session_type_auto_update') {
              if (this.autoUpdateToSessionType === 'phone') {
                temp = this.$gettext('This assignment was set to phone. <br> %{autoUpdateTimeF}');
                this.setSessionTypeToolTipText(this.$gettextInterpolate(temp, {autoUpdateTimeF: this.autoUpdateTimeF}));
                return 'phone_changed';
              }
            }
        }
      },
      confirmedByStatus() {
        let temp = '';
        if (this.confirmationBy) {
          temp = this.$gettext('This assignment need to be confirmed by: <br> %{confirmationDeadline}');
          this.setConfirmedByToolTipText(this.$gettextInterpolate(temp, {confirmationDeadline: this.confirmationDeadline}));
          return 'scheduled_confirmation';
        } else if (!this.confirmationBy) {
          this.setConfirmedByToolTipText(this.$gettext('Normal confirmation.'));
          return 'normal_confirmation';
        }
      },
      standByByStatus() {
        if (this.standbyProcessing === 'turned_on') {
          this.setStandByToolTipText(this.$gettext('This assignment is suitable for Standby interpreters.'));
          return 'suitable_for_standby';
        } else {
          this.setStandByToolTipText(this.$gettext('This assignment is not suitable for Standby interpreters.'));
          return 'not_suitable_for_standby';
        }
      },
      alternativeLangStatus() {
        let temp = '';
        if (this.alternativeLangToId === null) {
          this.setAlternativeLangToolTipText(this.$gettext('No alternative language.'));
          return 'no_alternative_language';
        } else if (this.alternativeLanguageActivated) {
          temp = this.$gettext('Alternative language name is applied.<br> %{autoUpdateTimeF}');
          this.setAlternativeLangToolTipText(this.$gettextInterpolate(temp, {autoUpdateTimeF: this.autoUpdateTimeF}));
          return 'changed_to_alternative_language';
        } else if (this.alternativeLangToId && !this.alternativeLanguageActivated) {
          temp = this.$gettext('Alternative language name will be applied at: <br> %{autoUpdateTimeF}');
          this.setAlternativeLangToolTipText(this.$gettextInterpolate(temp, {autoUpdateTimeF: this.autoUpdateTimeF}));
          return 'has_alternative_language';
        }
      },
      qualificationStatus() {
        let temp = '';
        if (this.qualificationProcessing === 'qualification_no_action' || !this.specificQualificationRequested) {
          this.setQualificationToolTipText(this.$gettext('No specific qualification requested.'));
          return 'no_qualification_preference';
        } else if (this.qualificationProcessing === 'qualification_cancel_not_found') {
          temp = this.$gettext('Cancel this assignment if no interpreters can be confirmed for %{level} qualification level.<br> %{autoUpdateTimeF}');
          this.setQualificationToolTipText(this.$gettextInterpolate(temp, {level: this.qualificationName, autoUpdateTimeF: this.autoUpdateTimeF}));
          return 'cancel_qualification_preference';
        } else if (this.qualificationProcessing === 'qualification_auto_update') {
          if (!this.specificQualificationRequested) {
            this.setQualificationToolTipText(this.$gettext('No specific qualification requested.'));
            return 'no_qualification_preference';
          } else if (this.autoUpdateTimeHasPast) {
            temp = this.$gettext('Qualification preference has been reset. <br> %{autoUpdateTimeF}');
            this.setQualificationToolTipText(this.$gettextInterpolate(temp, {autoUpdateTimeF: this.autoUpdateTimeF}));
            return 'qualification_preference_reset';
          } else {
            temp = this.$gettext('If no interpreters can be confirmed for %{level} qualification level, find an interpreter with another qualification. <br> %{autoUpdateTimeF}');
            this.setQualificationToolTipText(this.$gettextInterpolate(temp, {level: this.qualificationName, autoUpdateTimeF: this.autoUpdateTimeF}));
            return 'has_qualification_preference';
          }
        }
      },
      genderStatus() {
        let temp = '';
        if (this.genderProcessing === 'gender_no_action') {
          this.setGenderToolTipText(this.$gettext('No preference.'));
          return 'no_gender_preference';
        } else if (this.genderProcessing === 'gender_cancel_not_found') {
          if (this.gender === 'male') {
            temp = this.$gettext('Cancel this assignment if no male interpreters can be confirmed.<br> %{autoUpdateTimeF}');
            this.setGenderToolTipText(this.$gettextInterpolate(temp, {autoUpdateTimeF: this.autoUpdateTimeF}));
            return 'cancel_male_preference';
          } else if (this.gender === 'female') {
            temp = this.$gettext('Cancel this assignment if no female interpreters can be confirmed. <br> %{autoUpdateTimeF}');
            this.setGenderToolTipText(this.$gettextInterpolate(temp, {autoUpdateTimeF: this.autoUpdateTimeF}));
            return 'cancel_female_preference';
          }
        } else if (this.genderProcessing === 'gender_auto_update') {
          if (this.autoUpdateTimeHasPast) {
            temp = this.$gettext('No interpreters found, gender preference has been reset. <br> %{autoUpdateTimeF}');
            this.setGenderToolTipText(this.$gettextInterpolate(temp, {autoUpdateTimeF: this.autoUpdateTimeF}));
            return 'gender_preference_reset';
          } else {
            if (this.gender === 'female') {
              temp = this.$gettext('Set this assignment to both genders if no female interpreters can be confirmed. <br> %{autoUpdateTimeF}');
              this.setGenderToolTipText(this.$gettextInterpolate(temp, {autoUpdateTimeF: this.autoUpdateTimeF}));
              return 'has_female_gender_preference';
            } else if (this.gender === 'male') {
              temp = this.$gettext('Set this assignment to both genders if no male interpreters can be confirmed. <br> %{autoUpdateTimeF}');
              this.setGenderToolTipText(this.$gettextInterpolate(temp, {autoUpdateTimeF: this.autoUpdateTimeF}));
              return 'has_male_gender_preference';
            }
          }
        }
      },
      directJobStatus() {
        let temp = '';
        if (this.directProcessing === 'direct_no_action') {
          this.setDirectJobToolTipText(this.$gettext('No preference.'));
          return 'no_interpreter_preference';
        } else if (this.directProcessing === 'direct_cancel_said_no') {
          temp = this.$gettext('Cancel this assignment if %{name} isn\'t available. <br> %{time}');
          this.setDirectJobToolTipText(this.$gettextInterpolate(temp, {name: this.directlyInvitedInterpreterName, time: this.autoUpdateTimeF}));
          return 'cancel_if_interpreter_declined is_avatar';
        } else if (this.directProcessing === 'direct_find_another') {
          if (this.autoUpdateTimeHasPast) {
            if (this.directlyInvitedInterpreterAbandonedAt) {
              temp = this.$gettext('%{name} isn\'t available, requirement has been reset. <br> %{autoUpdateTimeF}');
              this.setDirectJobToolTipText(this.$gettextInterpolate(temp, {name: this.directlyInvitedInterpreterName, autoUpdateTimeF: this.autoUpdateTimeF}));
              return 'direct_interpreter_declined_find_other';
            } else if (!this.directlyInvitedInterpreterAbandonedAt) {
              this.setDirectJobToolTipText(this.directlyInvitedInterpreterName);
              return 'direct_interpreter_accepted is_avatar';
            }
          } else {
            temp = this.$gettext('Find another interpreter if %{name} isn\'t available. <br> %{autoUpdateTimeF}');
            this.setDirectJobToolTipText(this.$gettextInterpolate(temp, {name: this.directlyInvitedInterpreterName, autoUpdateTimeF: this.autoUpdateTimeF}));
            return 'find_other_interpreters_if_declined is_avatar';
          }
        }
      },
      lastActivityText() {
        const lastActivity = helpers.getJobInfo.lastActivity(this, this.lastActivityType);
        return `${lastActivity} <br> ${this.lastActivityAt}`;
      },
      hintIcons() {
        return [
          {
            id: 'session_type',
            classes: [this.sessionTypeStatus],
            toolTipText: this.sessionTypeToolTipText,
            condition: true,
            onClick: () => { this.openEditAssignmentInformation(); }
          },
          {
            id: 'auto_invite',
            classes: [this.autoInviteStatus],
            toolTipText: this.autoInviteToolTipText,
            condition: !this.hasAwardedInterpreter,
            onClick: () => {
              this.openEditProcessRequirement();
            }
          },
          {
            id: 'application',
            classes: [this.dangerLevelStatus.class],
            toolTipText: this.applicationToolTipText,
            condition: !this.hasAwardedInterpreter,
            onClick: () => {
              this.$store.commit('EnterpriseStore/setOwnerUid', this.propData?.ownerUID);
              this.openInvitationsAndApplications();
            }
          },
          {
            id: 'auto_award',
            classes: [this.autoAwardStatus],
            toolTipText: this.autoAwardToolTipText,
            condition: !this.hasAwardedInterpreter,
            onClick: () => {
              this.openEditProcessRequirement();
            }
          },
          {
            id: 'standby',
            classes: [this.standByByStatus],
            toolTipText: this.standByToolTipText,
            condition: !this.hasAwardedInterpreter,
            onClick: () => {
              this.openEditProcessRequirement();
            }
          },
          {
            id: 'confirmation',
            classes: [this.confirmedByStatus],
            toolTipText: this.confirmedByToolTipText,
            condition: !this.hasAwardedInterpreter,
            onClick: () => {
              this.openEditAdminTools();
            }
          },
          {
            id: 'gender_preference',
            classes: [this.genderStatus],
            toolTipText: this.genderToolTipText,
            condition: true,
            onClick: () => { this.openEditAssignmentInformation(); }
          },
          {
            id: 'direct_job',
            classes: this.directJobStatus ? this.directJobStatus?.split(' ') : [],
            toolTipText: this.directJobToolTipText,
            avatarPath: this.directlyInvitedInterpreterAvatarUrl,
            condition: true,
            onClick: () => {}
          },
          {
            id: 'alternative_language',
            classes: [this.alternativeLangStatus],
            toolTipText: this.alternativeToolTipText,
            condition: true,
            onClick: () => { this.openEditAssignmentInformation(); }
          },
          {
            id: 'qualification_preference',
            classes: [this.qualificationStatus],
            toolTipText: this.qualificationToolTipText,
            condition: true,
            onClick: () => { this.openEditAssignmentInformation(); }
          },
          {
            id: 'last_activity',
            classes: ['last_activity'],
            toolTipText: this.lastActivityText,
            condition: !this.hasAwardedInterpreter,
            onClick: () => {}
          },
        ].filter((hint) => hint.condition !== false);
      },
    },
    methods: {
      setAutoInviteToolTipText(value) { this.autoInviteToolTipText = value; },
      setApplicationToolTipText(value) { this.applicationToolTipText = value; },
      setAutoAwardToolTipText(value) { this.autoAwardToolTipText = value; },
      setSessionTypeToolTipText(value) { this.sessionTypeToolTipText = value; },
      setConfirmedByToolTipText(value) { this.confirmedByToolTipText = value; },
      setStandByToolTipText(value) { this.standByToolTipText = value; },
      setAlternativeLangToolTipText(value) { this.alternativeToolTipText = value; },
      setQualificationToolTipText(value) { this.qualificationToolTipText = value; },
      setGenderToolTipText(value) { this.genderToolTipText = value; },
      setDirectJobToolTipText(value) { this.directJobToolTipText = value; },
      changeSelectedManager(value) {
        if (value == 0) {
          this.assignedManagerUid = '';
        } else {
          this.assignedManagerUid = value;
        }
        const data = {
          payload: {
            manager_uid: this.assignedManagerUid === '' ? null : this.assignedManagerUid,
          },
          id: this.jobId
        };
        this.$store.dispatch('ManagerSocketDataStore/assignManagerToJobAssignment', data);
      }
    }
  };
</script>
<style scoped>
.hints {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.hints__wrapper {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.hints__manager-select {
  max-width: 30px;
}
</style>
